import '../scss/app.scss';
import UIkit from 'uikit';
import './controller';
import { get as getCandidates } from '../../../global/js/registry';
import Candidate from '../../../global/js/candidate';
import Component from '../../../global/js/component';
import { IGlobalModalProps } from 'global/interfaces/component.interface';

const Icons = require('./../../../global/assets/svg-icons');
const modalTemplate = require('components/organisms/o-modal/o-modal.template.twig') as (data: any) => string;

class App {
	endpoints: Record<string, string> = {};
	dataEndpointsId = 'data-endpoints';
	globalModalContainerId = 'global-modal-container';
	dynamicModalContentPrefix = 'dynamic-modal-content--';
	registrableRemoteComponentsPrefixes = {
		layoutModal: '#layout-',
		wolfLayerModal: '#wolf-layer-',
		dynamicModal: '#dynamic-modal-',
		navCanvas: '#nav-offcanvas--',
	};

	constructor() {
		// loads the uikit icon plugin
		(UIkit as any).use(Icons);
		// initialize custom component js
		document.addEventListener('DOMContentLoaded', this.onDOMContentLoaded.bind(this), { once: true });
	}

	onDOMContentLoaded() {
		this.getAppData();
		this.mountComponents();
		this.registerModalOpeners();
		this.registerCanvasOpeners();
	}

	getAppData(): void {
		const endpointsData = document.getElementById(this.dataEndpointsId);
		if (endpointsData) {
			const endpoints = JSON.parse(endpointsData.innerText) as Record<string, string>;
			this.endpoints = endpoints;
		}
	}

	async mountComponents(reinit: boolean = false): Promise<void> {
		const promises: Array<Promise<Element[]>> = getCandidates().map((candidate: Candidate) => candidate.define(reinit));
		await Promise.all(promises);
	}

	protected mountComponent(component: Component): void {
		component.mountCallback();
		component.markAsMounted();
	}

	protected isComponent(element: Element): boolean {
		// it needs to be changed into `instanceof` check once the following issue get solved:
		// {@link https://github.com/webcomponents/custom-elements/issues/64}
		const component: Component = <Component>element;
		return true;
		return !!component.name;
	}

	async printDynamicModalOnScreen(contentId: string): Promise<void> {
		const newModalTriggerHref = this.registrableRemoteComponentsPrefixes.dynamicModal + contentId;
		const newModalId = newModalTriggerHref.substring(1);
		const newModalContentId = 'content--dynamic-modal-' + contentId;

		const modalIdHasBeenAlreadyRendered = !!document.getElementById(newModalId);
		if (modalIdHasBeenAlreadyRendered) return UIkit.modal(newModalTriggerHref).show();

		const loadingDataModal: IGlobalModalProps = {
			id: newModalId,
			content: `<div class="uk-flex uk-flex-center uk-magin-large"><span uk-spinner="ratio: 3"></span></div>`,
			closeButton: {
				text: '',
				type: 'default',
				title: 'Button title',
			},
			closeButtonPosition: 'top',
			width: 'half',
			modalPosition: 'right',
		};

		const modalContainer = document.getElementById(this.globalModalContainerId);
		const modalAsAString = modalTemplate({ data: loadingDataModal });
		const modalFragment = this.parseStringToNode(modalAsAString);

		if (modalContainer) {
			modalContainer.innerHTML = '';
			modalFragment && modalContainer.appendChild(modalFragment);
		}

		UIkit.modal(newModalTriggerHref).show();

		const getModalContent = async () => {
			if (this.endpoints.dynamicModal) {
				try {
					const response = await fetch(this.endpoints.dynamicModal + `?contentId=${contentId}`);
					const modalResponse = await response.json();
					return modalResponse.content;
				} catch (e) {
					console.log(e);
				}
			}
		};

		const modalContentAsString = await getModalContent();
		const safeModalContentAsHTML = this.parseStringToNode(`<div>${modalContentAsString}</div>`);
		const modalContentElement = document.getElementById(newModalContentId);
		if (modalContentElement) {
			modalContentElement.innerHTML = '';
			safeModalContentAsHTML !== null && modalContentElement.appendChild(safeModalContentAsHTML);
		}
	}

	parseStringToNode(string: string): ChildNode | null {
		return new DOMParser().parseFromString(string, 'text/html').body.firstChild;
	}

	registerRemoteControlledComponent(hashtagIdPrefix: string, eventToListen: string, callback: (id: string, dataset?: any) => any): void {
		const controlledComponentNodesList = [...document.querySelectorAll(`a[href^='${hashtagIdPrefix}']`)];
		controlledComponentNodesList.forEach(function (value) {
			value.addEventListener(eventToListen, (e) => {
				e.preventDefault();
				const cleanId = value.getAttribute('href')?.split(hashtagIdPrefix)[1];
				cleanId && callback(cleanId);
			});
		});
	}

	registerModalOpeners(): void {
		this.registerRemoteControlledComponent(this.registrableRemoteComponentsPrefixes.layoutModal, 'click', (name: string) =>
			UIkit.modal('#' + name).show(),
		);
		this.registerRemoteControlledComponent(this.registrableRemoteComponentsPrefixes.wolfLayerModal, 'click', (name: string) =>
			UIkit.modal('#' + name).show(),
		);
		this.registerRemoteControlledComponent(this.registrableRemoteComponentsPrefixes.dynamicModal, 'click', (name: string, dataset: any) =>
			this.printDynamicModalOnScreen(name),
		);
	}

	registerCanvasOpeners(): void {
		this.registerRemoteControlledComponent(this.registrableRemoteComponentsPrefixes.navCanvas, 'click', (name) => {
			if (UIkit.toggle('#' + name)) {
				UIkit.toggle('#' + name).toggle();
			}
		});
	}
}

export default new App();
