import { CustomElementModule, CustomElementImporter } from './registry';

export default class Candidate {
	protected readonly tagName: string;
	protected readonly customElementImporter: CustomElementImporter;
	protected isCustomElementDefined: boolean;

	/**
	 * Creates an instance of Candidate.
	 *
	 * @param tagName HTML component tagname.
	 * @param customElementImporter Function that executes webpack's import() to asyncronously retrieve the component
	 * constructor.
	 */
	constructor(tagName: string, customElementImporter: CustomElementImporter) {
		this.tagName = tagName;
		this.customElementImporter = customElementImporter;
		this.isCustomElementDefined = false;
	}

	/**
	 * Defines the webcomponent on which the current candidate is based.
	 * First, the function asyncronously retrieves the component constructor using webpack's import().
	 * Then, tagName and contructor are used to define the component using customElements browser API.
	 *
	 * @returns A promise with all the defined elements as resolve() argument.
	 */
	async define(reinit: boolean = false): Promise<any[] | Element[]> {
		const elementCollection: NodeListOf<Element> = document.querySelectorAll(`[data-web-component="${this.tagName}"]`);
		if (elementCollection.length === 0) {
			return [];
		}

		const elements: Element[] = Array.from(elementCollection);

		if (this.isCustomElementDefined && !reinit) {
			return elements;
		}

		try {
			const customElementModule: CustomElementModule = await this.customElementImporter();
			elements.map((e) => new customElementModule.default(e));
		} catch (err) {
			throw new Error(`${this.tagName} failed to be defined\n${err.message}`);
		}

		this.isCustomElementDefined = true;

		return elements;
	}
}
